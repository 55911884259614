<template>
  <div class="space-y-6 mt-4">
    <div class="pageTitle">
      <h1>{{ $route.name }}</h1>
    </div>
    <!-- content -->
    <div class="max-w-4xl space-y-6 divide-y divide-gray-400">
      <div class="pt-6" v-for="(item, index) in items" :key="index">
        <div class="text-base sm:text-lg">
          <!-- Expand/collapse question button -->
          <button
            class="
              flex
              items-start
              justify-between
              w-full
              text-left text-gray-400
              focus:outline-none
            "
            @click="item.show = !item.show"
          >
            <span class="font-medium text-gray-800"
              >{{ index + 1 }}) {{ item.title }}</span
            >

            <span class="flex items-center ml-6 text-gray-600 h-7">
              <i
                class="mdi mdi-chevron-down text-3xl transition transform"
                :class="item.show ? 'rotate-0' : '-rotate-180'"
              ></i>
            </span>
          </button>
        </div>
        <transition name="fade">
          <div class="pr-12 mt-2" v-if="item.show">
            <p class="text-sm text-gray-700 sm:text-base" v-html="item.text" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    "$store.state.selectedPartner": {
      immediate: true,
      deep: true,
      handler(partner) {
        // this is added here because of the embedded href path
        if (!partner) return;
        let questionOne = {
          title: "Will my vacations be extended due to Covid-19?",
          text: `The latest information on how Covid-19 might affect your package <a class='href' href="/${this.$store.state.selectedPartner.id}/contact">can be found here.</a>`,
          show: false,
        };
        this.items.unshift(questionOne);
      },
    },
  },
  data: () => ({
    items: [
      {
        title: "What is a Package Code?",
        text: `A Package Code is an alpha-numeric sequence which identifies a vacation package offered by Silent Auction Vacations. You will use this code to locate information on the type of accommodations, number of nights, maximum number of guests, extras such as tours, greens fees etc., and the terms and conditions that govern your package. This code will be present on all SAV documents relating to your vacation. <strong>(Examples: SAV 123, WAHPNA)</strong>`,
        show: false,
      },
      {
        title: "What is a Unique ID?",
        text: `A Unique ID is an identification number that is attached to your individual vacation package. No other package will have the same number and it helps SAV team members to track important information regarding the booking of your vacation.  <strong>(Example: 136-041019-094...)</strong>`,
        show: false,
      },
      {
        title:
          "I recently bid on and won a vacation package; when will I receive my information?",
        text: `When the auction is over, the event organizer will order your vacation package from Silent Auction Vacations.  Upon receiving the order and payment, Silent Auction Vacations will e-mail your <strong>Registration and Reservation Access Code</strong> to the organization, which they will in turn forward to you. Generally, this process requires less than 30 days to complete.  In the event that you do not have your registration and reservation information within 30 days, please contact the event sponsor or organizer.`,
        show: false,
      },
      {
        title: "What’s included in my package?",
        text: `Accommodations and inclusions vary widely from package to package. For the details of your package, <a class='href' href="https://savtravelerportal.com" target="_blank">click here and sign in to your traveler dashboard.</a>`,
        show: false,
      },
      {
        title: "Will airfare be included with my package?",
        text: `Silent Auction Vacations offers very few packages that include airfare. To view the inclusions for your package, <a class='href' href="https://savtravelerportal.com" target="_blank">click here and sign in to your traveler dashboard.</a>`,
        show: false,
      },
      {
        title:
          "When do I get tour tickets, admissions, greens fees, etc. that are included in my package?",
        text: `If your package includes extras such as tours, show tickets, greens fees etc., an SAV representative will contact you within 14 days after we have confirmed your selected accommodations and travel dates.  The representative will provide you with all available options and information about your package inclusions.`,
        show: false,
      },
      {
        title: "Where can I view resorts available at my destination?",
        text: `Once you have registered your package and accessed the inventory selection page of our reservations website, you will find lists of available resorts and dates for your selection.`,
        show: false,
      },
      {
        title: "Can I give my package to someone else?",
        text: `Yes, packages are fully transferable and therefore make perfect gifts! It is safe to register a package, even if you are not sure that you give it as a gift. There are no fees to transfer registered packages. If the package has been registered, you may message us and request to gift it to another by clicking "Contact Us" from your Traveler Dashboard.`,
        show: false,
      },
      {
        title: "Can I sell my package?",
        text: `No.  Packages may be gifted but may not be sold.`,
        show: false,
      },
      {
        title:
          "Can I go for less nights in exchange for a larger unit or different resort type?",
        text: `Silent Auction Vacations contracts with partner resorts for specific accommodation types and number of nights to match each package code. Often, upgrades to unit types with the ability to accommodate larger parties for the number of nights included in your package may be available for a fee.  However, decreasing the number of nights will not create a <strong>“credit”</strong> that can be applied to booking a larger unit.

`,
        show: false,
      },
      {
        title: "If can’t use my package, can I get a refund?",
        text: `Your winning bid was a generous donation to further the cause of the sponsoring organization.  The vacation package is a thank-you gift from the sponsor for your generous donation, therefore the package is non-refundable.  However, you may give the package to a friend or family member in the event that you are unable to enjoy the package yourself.  Gifting of your package must occur prior to registering the package.`,
        show: false,
      },
      {
        title: "How do I book my reservation?",
        text: `Within 30 days of your winning bid, you should have received an email from the charity providing a link to savreservations.com. By following this link, you will register your package and receive access to available resorts and travel dates.`,
        show: false,
      },
      {
        title: "How far in advance may I make a reservation?",
        text: `When using the inventory selection page on the SAV booking website available resorts and arrival dates are displayed beginning as little as 90 days from to today to as far out as 10 months. Standard reservation requests must provide at least 90 days advance notice of travel and are generally not available for arrival dates that are more than 10 months out. When using the <strong>Stay Safer, Close to Home</strong> option you may request arrival dates between 45 day and 180 days from submission of your request.`,
        show: false,
      },
      {
        title: "When must I book my vacation?",
        text: `Requests for travel must be received by Silent Auction Vacations at least 90 days prior to you requested arrival date.`,
        show: false,
      },
      {
        title: "When can I travel?",
        text: `Typically, reservations may be made between 90 days and 10 months in advance of your desired date of travel.`,
        show: false,
      },
      {
        title: "Can I change my destination?",
        text: `Historically, destination changes have not been an option. However, as part of the SAV response to the Covid crisis you may request a different destination using the Stay Safer, Close to Home program. This program offers access to over 50 regional destinations throughout the U.S. and Canada.`,
        show: false,
      },
      {
        title: "Can I change a reservation once it is confirmed?",
        text: `<strong>All confirmed reservations are final, and no changes of any kind are allowed.</strong> The only exception to this policy is cancelations that are required due to governmental prohibition of travel to your confirmed destination or in the event that the resort is unable to provide your accommodation. If you feel that your confirmed reservation is meets the parameters of this narrow exception, please contact us using any of the contact us buttons that appear throughout our websites.`,
        show: false,
      },
      {
        title: "How can I reschedule my confirmed reservation?",
        text: `<strong>All confirmed reservations are final, and no changes of any kind are allowed.</strong> The only exception to this policy is cancelations that are required due to governmental prohibition of travel to your confirmed destination or in the event that the resort is unable to provide your accommodation. If you feel that your confirmed reservation is meets the parameters of this narrow exception, please contact us using any of the contact us buttons that appear throughout our websites.`,
        show: false,
      },
      {
        title: "How can I add an additional person to our vacation?",
        text: `Only the name of the registered traveler will appear on the reservation confirmation. The person whose name is on the reservation must be present at check-in with a valid government issued ID containing the name on the reservation confirmation. The maximum number of travelers that may occupy the accommodation appears on the reservation. The names of the travelers in the party other than the registered party are not required to secure the reservation.`,
        show: false,
      },
      {
        title: "When can I travel? Are there black-out dates?",
        text: `Generally, SAV packages allow for year-round travel. If travel is restricted by preset “blacked-out dates” the valid and restricted periods can be found in your package terms. To review those terms, <a class='href' href="https://savtravelerportal.com" target="_blank">click here and sign in to your traveler dashboard.</a>`,
        show: false,
      },
      {
        title: "Why are there high season surcharges when I want to travel?",
        text: `The goal of this program is to help charities raise the money that they need to serve their community.  After years of experience, we have discovered that most of our package winners do not desire to travel during peak seasons.  Therefore, our prices to the charity are based on off-peak travel rates which allow us to keep our package prices low and to allow charities to maximize their income from each donation.  Rather than blackout peak seasons, we allow year-round use of our packages by implementing modest surcharges on those winners who do desire to travel during peak periods.`,
        show: false,
      },
      {
        title: "When does my package expire?",
        text: `For packages issued prior to <strong>01/01/2020,</strong> your package will expire 18 months after the date of the event where you won the package.  For packages sold between <strong>01/01/2020</strong> and <strong>08/31/2020,</strong> your package will expire 24 months after the date of the event where you won the package. For packages sold after August 31, 2020, your package is valid for travel for up to 3 years* following the date of your event. Travel in third year may be subject to a surcharge not to exceed $599**. <br/><br/> <i>**Surcharges may be significantly higher for select packages requiring the purchase of tickets.</i>`,
        show: false,
      },
      {
        title: "My package is about to expire, what are my options?",
        text: `SAV packages won in auctions or raffles before <strong>01/01/2020</strong> expire 18 months after the date of the auction or event. Those winners have the right to extend the valid travel period for their package by up to 6 months beyond the original expiration date of the package. There is an extension fee of $399-$599** required when exercising this extension (depending upon location). The extension must be requested, and the fee must be paid at least 30 days prior to the original expiration date of the package or at the time of booking a reservation which has an arrival date that falls within the extended travel period, whichever comes first. Packages issued after <strong>01/01/2020</strong> are valid for either 2 or 3 years, depending on the issue date. Extended travel periods are not available for these packages. (See FAQ #23) <br/><br/> <i>**Surcharges may be significantly higher for select packages requiring the purchase of tickets.</i>`,
        show: false,
      },
      {
        title: "How do I pay my extension fee?",
        text: `<a class='href' href="https://savreservations.com/39byb16q/" target="_blank">Please click on this link</a> and complete the required information on the secure payment form.`,
        show: false,
      },
      {
        title: "What does registering my package mean?",
        text: `When packages are delivered to the organization the package has been assigned to the organization and not to a specific future traveler. The Package Code and Unique ID become the property of the future traveler when the registration is completed. Therefore, if you are traveling using the package then you will provide your information at registration or you may gift the package to another person by providing them with your travel redemption link and they will will register providing their information, or you may register the package and then request to transfer it to another person. In either case, registration is free and easy. Name changes are not allowed for any reason after travel reservations are complete.`,
        show: false,
      },
    ],
  }),
};
</script>